<template>
  <div>
    <v-progress-circular
      v-if="status == 0"
      size="24"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <div v-if="status == 1">
      <v-tooltip v-model="show" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon style="vertical-align: middle" color="#FF0000">
            mdi-alert-circle-outline
          </v-icon>
        </template>
        <span>Ya está registrado</span>
      </v-tooltip>
    </div>

    <v-icon v-if="status == 2" style="vertical-align: middle" color="green">
      mdi-checkbox-marked-circle-outline
    </v-icon>

    <div></div>
  </div>
</template>
<script>
export default {
  name: "IconStates",
  data() {
    return {
      show: true
    };
  },
  props: {
    status: {
      Number: Number,
      default: null
    }
  }
};
</script>
